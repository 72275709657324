import React, { useState } from "react";

import "./styles/Rules.css";

const Rules = () => {
    const emblemes = require.context("../medias/embleme", false, /\.png$/);
    const images = Array.from({ length: 130 }, (_, index) => emblemes(`./${index + 1}.png`));

    const [fullscreenImage, setFullscreenImage] = useState(null);

    const handleImageClick = (src) => {
        setFullscreenImage(src);
    };
    
    const closeFullscreen = () => {
        setFullscreenImage(null);
    };


    return (
        <main className="rules">
            <h1><br />REGLEMENT</h1>
            <div className="rules-container">
                <div className="rules-section">
                    <h3>Article 1 : Objet du Jeu</h3>
                    <p>
                        L'Épopée est un jeu de gestion d'équipe de cyclisme en ligne qui permet aux joueurs de créer et de gérer leurs propres équipes de coureurs professionnels.
                        Les points sont attribués en fonction des performances réelles des coureurs durant les courses tout au long de la saison.
                    </p>
                </div>

                <div className="rules-section">
                    <h3>Article 2 : Composition de l'Équipe</h3>
                    <p>
                        1. Les équipes doivent être composées de 27 à 30 coureurs.<br />
                        2. Chaque joueur dispose d'un budget de 500 points pour constituer son équipe.<br />
                        3. Les coureurs ont des valeurs allant de 3 à 122 points, basées sur leurs performances sur les deux dernières saisons (70% 2024 et 30% 2023).<br />
                        4. Les équipes peuvent être modifiées à 3 reprises à des moments clés de la saison (14 et 15 avril - 2 et 3 juin - 31 juillet et 1 août) grâce à 4 transferts de coureurs. Un coureur échangé ne peut pas être récupéré.<br />
                        5. La valeur des 3 coureurs recrutés doit être inférieure ou égale aux coureurs vendus.<br />
                        6. Un coureur avec une valeur de 3 ou 4 a la possibilité d'obtenir un bonus de point pour la saison (Point multiplié par deux). <strong>Le choix peut être changé chaque mois (nouveauté).</strong><br />
                        7. Les effectifs sont modifiables, tout comme le calendrier des courses et des objectifs jusqu'à la première course de la saison, le 21 janvier. Il est possible de créer une équipe toute la saison.<br />
                        8. Les joueurs ont le droit à deux équipes, qu'elles soient créées via deux adresses mails différentes.
                    </p>
                </div>

                <div className="rules-section">
                    <h3>Article 3 : Sélection des Courses et Points</h3>
                    <p>
                        1. Les coureurs sont invités sur toutes les courses WT mais doivent choisir 42 courses Pro Series et 45 continental Tour. Le but étant de faire des choix et de pousser la stratégie au maximum.<br />
                        2. Les choix se font au fur et à mesure, par période, en même temps que les transferts <strong>(Nouveauté)</strong>.<br />
                        3. Les joueurs doivent sélectionner trois courses comme objectifs principaux en début de saison, en WT, en Pro Series, et en Continental <strong>(Nouveauté)</strong>.<br />
                        4. Les points des coureurs sont doublés lors de ces trois courses sélectionnées, ajoutant une dimension stratégique supplémentaire.
                    </p>
                </div>

                <div className="rules-section">
                    <h3>Article 4 : Transferts</h3>
                    <p>
                        1. Un transfert permet de remplacer un coureur de l'équipe par un autre de valeur inférieure ou égale.<br />
                        2. Les managers peuvent effectuer des transferts à des moments spécifiques de la saison course précisée dans l'article 2.<br />
                        3. Les points du coureur vendu sont conservés après son départ et les points de la recrue ne sont comptés qu'à partir du lendemain de son arrivée.
                    </p>
                </div>

                <div className="rules-section">
                    <h3>Article 5 : Système de Points</h3>
                    <p>
                        1. Les points sont attribués en fonction des performances réelles des coureurs dans les courses professionnelles.<br />
                        2. Des points supplémentaires peuvent être gagnés pour des réalisations spéciales, comme le port du maillot de leader ou des distinctions honorifiques.<br />
                        3. Les résultats sont mis à jour dans les 24 heures après la course.
                    </p>
                </div>

                <div className="rules-section">
                    <h3>Article 6 : Emblèmes (Nouveauté)</h3>
                    <p>
                        1. Les emblèmes sont des récompenses que vous obtenez durant la saison ou en fin de saison, lorsque vous réussissez une performance notable avec votre équipe.<br />
                        2. Il existe quatre catégories d'emblèmes : <strong>Récompenses</strong> (Position au classement général par période et fin de saison), <strong>Spécialistes</strong> (Correspondant à votre style de joueurs), <strong>Challenges</strong> (Défis difficiles) et <strong>Effectif</strong> (Lié à vos choix de coureurs au début de l'année).<br />
                        3. Ces emblèmes définissent votre style de joueurs et vous offrent la possibilité de vous comparer à vos adversaires. Obtenez-les tous !<br />
                        4. Vous pouvez retrouver l'ensemble des emblèmes disponibles ci-dessous.
                    </p>
                </div>

                <div className="rules-section">
                    <h3>Article 7 : Fin de Saison</h3>
                    <p>
                        1. À la fin de la saison, les équipes seront dissoutes.<br />
                        2. Une nouvelle saison implique de nouvelles équipes pour chaque manager.
                    </p>
                </div>

                <div className="rules-section">
                    <h3>Vie du Jeu et Interactivité</h3>
                    <p>
                        La vie du jeu dépend de l'engagement des joueurs. Nous vous invitons à en parler au maximum sur les réseaux sociaux, sous le <strong>#EpopéeVF</strong>.
                    </p>
                    <p><strong>Note :</strong> Les règles sont susceptibles d'être ajustées pour assurer l'équité et l'amélioration de l'expérience de jeu. Les changements seront communiqués aux joueurs de manière transparente.</p>
                </div>
                <h2>TOUS LES EMBLEMES DISPONIBLES</h2>
                <div className="rules-emblemes-container">
                    <h3>Le Spécialiste</h3>
                    <div className="rules-emblemes">
                        {images.slice(0, 42).map((src, index) => (
                            <img key={index} src={src} alt={`Emblem ${index + 1}`} onClick={() => handleImageClick(src)} />
                        ))}
                    </div>
                </div>
                <div className="rules-emblemes-container2">
                    <h3>Challenges</h3>
                    <div className="rules-emblemes">
                        {images.slice(43, 85).map((src, index) => (
                            <img key={index} src={src} alt={`Emblem ${index + 1}`} onClick={() => handleImageClick(src)} />
                        ))}
                    </div>
                </div>
                <div className="rules-emblemes-container">
                    <h3>Effectifs</h3>
                    <div className="rules-emblemes">
                        {images.slice(86, 103).map((src, index) => (
                            <img key={index} src={src} alt={`Emblem ${index + 1}`} onClick={() => handleImageClick(src)} />
                        ))}
                    </div>
                </div>
                <div className="rules-emblemes-container2">
                    <h3>Récompenses</h3>
                    <div className="rules-emblemes">
                        {images.slice(104, 129).map((src, index) => (
                            <img key={index} src={src} alt={`Emblem ${index + 1}`} onClick={() => handleImageClick(src)} />
                        ))}
                    </div>
                </div>
            </div>
            {fullscreenImage && (
                <div className="rules-fullscreen-container" onClick={closeFullscreen}>
                    <img src={fullscreenImage} alt="Fullscreen Emblem" className="rules-fullscreen-image" />
                    <div className="rules-close-button" onClick={closeFullscreen}>✖</div>
                </div>
            )}
        </main>
    );
};

export default Rules;