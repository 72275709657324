import React, { useState, useEffect } from "react";
import "../styles/MyTeam.css";
import { useNavigate } from 'react-router-dom';

const MyTeam = ({ generalCyclists, espoirCyclists }) => {
    const [sortKey, setSortKey] = useState('');
    const [currentTeam, setCurrentTeam] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (generalCyclists) {
            setCurrentTeam(generalCyclists);
        }
    }, [generalCyclists]);

    const handleRowClick = (cyclistId) => {
        navigate(`/cyclistDetails/${cyclistId}`);
    };

    const getSortedCyclists = () => {
        return [...currentTeam].sort((a, b) => {
            switch (sortKey) {
                case "value":
                    return b.finalValue - a.finalValue;
                case "points":
                    return b.cyclistPoints - a.cyclistPoints;
                default:
                    return 0;
            }
        });
    };

    const handleSortChange = (event) => {
        setSortKey(event.target.value);
    };

    const handleTeamChange = (event) => {
        if (event.target.value === "espoir") {
            setCurrentTeam(espoirCyclists);
        } else {
            setCurrentTeam(generalCyclists);
        }
    };

    const sortedCyclists = getSortedCyclists();

    return (
        <section className="my-team">
            <div className="my-team-sort-control">
                <h2>Mon équipe :</h2>
                <label>Trier par</label>
                <select onChange={handleSortChange}>
                    <option value="value">Valeur</option>
                    <option value="points">Points</option>
                </select>
                <label>Choisir une équipe</label>
                <select onChange={handleTeamChange}>
                    <option value="general">Général</option>
                    <option value="espoir">Espoir</option>
                </select>
            </div>
            {currentTeam.length > 0 ? (
                <div className="my-team-table">
                    <div className="my-team-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>COUREUR</th>
                                    <th>ÉQUIPE</th>
                                    <th>VALEUR</th>
                                    <th>SPÉCIALITÉ</th>
                                    <th>POINTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedCyclists.map((cyclist) => (
                                    <tr key={cyclist.cyclistId} onClick={() => handleRowClick(cyclist.cyclistId)}>
                                        <td>
                                            <div className="my-team-name-info">
                                                {cyclist.isBonus === 1 && <p>x2</p>}
                                                <img 
                                                    className="select-cyclists-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality
                                                        .normalize("NFD")
                                                        .replace(/[\u0300-\u036f]/g, '')
                                                        .replace(/ /g, '_')
                                                        .toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${cyclist.nationality}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <span>{cyclist.name}</span>
                                            </div>
                                        </td>
                                        <td>{cyclist.team}</td>
                                        <td>{cyclist.finalValue}</td>
                                        <td>{cyclist.speciality}</td>
                                        <td>{cyclist.cyclistPoints}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <p>Aucun cycliste trouvé pour cette team.</p>
            )}
        </section>
    );
};

export default MyTeam;
