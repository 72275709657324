import React from "react";

import "../styles/SelectActualCyclists.css";

const SelectCyclists = ({ numberPlayerSelected, setNumberPlayerSelected, selectedCyclists, selectedActualCyclists, setSelectedActualCyclists, budget, setBudget, nextStep }) => {  
    const handleSelectCyclist = cyclist => {
        if (selectedActualCyclists.some(selected => selected.cyclistId === cyclist.cyclistId)) {
            setSelectedActualCyclists(selectedActualCyclists.filter(selected => selected.cyclistId !== cyclist.cyclistId));
            setNumberPlayerSelected(numberPlayerSelected - 1);
            setBudget(budget - cyclist.finalValue);
    
        } else {
            setSelectedActualCyclists([...selectedActualCyclists, cyclist]);
            setNumberPlayerSelected(numberPlayerSelected + 1);
            setBudget(budget + cyclist.finalValue);
        }
    };

    const isCyclistsSelected = cyclistId => {
        const isSelected = selectedActualCyclists.some(selected => selected.cyclistId === cyclistId);
        return isSelected;
    };

    const isButtonDisabled = selectedActualCyclists.length > 4;

    return (
        <main className="select-actual-cyclists">
            <div className="select-actual-cyclists-shadow-container">
                <div className="select-actual-cyclists-title">
                    <h1>JOUEURS INTERCHANGEABLE - 1/3 (ATTENTION : VOUS NE POURREZ ÉCHANGER VOS JOUEURS QU'UNE SEULE FOIS AUJOURD'HUI)</h1>
                    <div className="select-actual-cyclists-shadow-mask-right"></div>
                    <div className="select-actual-cyclists-shadow-mask-bottom"></div>
                </div>
            </div>
            <div className="select-actual-cyclists-header">
                <div className="select-actual-cyclists-rules">
                    <p >Sélectionnez des cyclistes (4 maximum) que vous voulez changer</p>
                </div>

                <div className="select-actual-cyclists-change-step">
                <button onClick={nextStep} disabled={isButtonDisabled}>VALIDER</button>
                </div> 
            </div>

            <div className="select-actual-cyclists-pannel-view">
                <div className="select-actual-cyclists-choice">
                    <div className="select-actual-cyclists-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>COUREUR</th>
                                    <th>ÉQUIPE</th>
                                    <th>NATION</th>
                                    <th>VALEUR</th>
                                    <th>SPÉCIALITÉ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedCyclists.map((cyclist) => (
                                    <tr key={cyclist.cyclistId} onClick={() => handleSelectCyclist(cyclist)} className={isCyclistsSelected(cyclist.cyclistId) ? "select-actual-cyclists-table-selected-row" : ""}>
                                        <td>
                                            <div className="select-actual-cyclists-name-info">
                                                <img 
                                                    className="select-actual-cyclists-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality
                                                        .normalize("NFD")
                                                        .replace(/[\u0300-\u036f]/g, '')
                                                        .replace(/ /g, '_')
                                                        .toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${cyclist.nationality}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <span>{cyclist.name}</span>
                                            </div>
                                        </td>
                                        <td>{cyclist.team}</td>
                                        <td>{cyclist.nationality}</td>
                                        <td>{cyclist.finalValue}</td>
                                        <td>{cyclist.speciality}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="select-actual-cyclists-warning">
                        <p>
                            <strong>Attention :</strong><br />
                            Attention : 
                            Ces coureurs peuvent être échangés à trois moments spécifiques au cours de la saison (8 avril - 31 mai - 31 juillet), permettant aux joueurs d'adapter leur équipe en fonction de la forme et des performances des cyclistes.
                            La valeur des 5 coureurs échangés devra être supérieure ou égale à la valeur des joueurs remplaçants.
                        </p>
                    </div>
                </div>

                <div className="select-actual-cyclists-line-container">
                    {selectedActualCyclists.map(cyclist => (
                        <div className="select-actual-cyclists-selected-cell" key={cyclist.cyclistId}>            
                            <div className="select-actual-cyclists-selected-info">
                                <img src={`${process.env.PUBLIC_URL}/icons/cancel.png`} className="select-actual-cyclists-cancel-icon" alt="Icone de suppression" width="17" height="17" onClick={() => handleSelectCyclist(cyclist)} />
                                <h4>
                                    <img 
                                        className="select-actual-cyclists-flag-icon"
                                        src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality.replace(/ /g, '_').toLowerCase()}.png`} 
                                        alt={`Drapeau de ${cyclist.nationality}`} 
                                        width="20" 
                                        height="20"
                                    />
                                    {cyclist.name}
                                </h4>
                                <p>{cyclist.team}</p>
                                <p>Valeur : <strong>{cyclist.finalValue}</strong></p>
                                <div style={{ width: "100%", height: "15px", backgroundColor: cyclist.teamColor, marginTop: "35px", marginLeft: "auto", marginRight: "auto" }}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>  
        </main>
    );
}
export default SelectCyclists;