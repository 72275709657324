import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

import "./styles/ChangeBonusCyclist.css";

const ChangeBonusCyclist = () => {
    const [selectedCyclists, setSelectedCyclists] = useState([]);
    const [cyclistsBonus, setCyclistsBonus] = useState([]);
    const navigate = useNavigate();
    const { user } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleCyclistsBonus = cyclist => {
        if (cyclistsBonus.some(changeable => changeable.cyclistId === cyclist.cyclistId)) {
            
            setCyclistsBonus(cyclistsBonus.filter(changeable => changeable.cyclistId !== cyclist.cyclistId));
        } else {
            
            setCyclistsBonus([...cyclistsBonus, cyclist]);
        }
    };

    const isCyclistsBonusSelected = (cyclistId) => {
        return cyclistsBonus.some(changeable => changeable.cyclistId === cyclistId);
    };

    useEffect(() => {
        if (!user) {
            navigate("/logIn");
            return;
        }         

        const fetchTeamData = async () => {
            try {

                const response = await fetch(`${apiUrl}/userTeam/1`, {
                    credentials: "include"
                });

                if (!response.ok) {
                    throw new Error(`Erreur lors de la récupération des données de l'équipe: Statut ${response.status} - ${response.statusText}`);
                }
                
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Erreur lors de la récupération des données de l'équipe:", error);
                throw error;
            }
        };
    
        const loadData = async () => {
            try {
                const teamDataExist = await fetchTeamData();

                if (teamDataExist) {
                    setSelectedCyclists(teamDataExist.selectedCyclists || []);
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error);
            }
        };
    
        loadData();
        
    }, [apiUrl, user, navigate]);

    const handleSaveBonusCyclist = async () => {

        try {
            const response = await fetch(`${apiUrl}/changeCyclistBonus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ cyclistsBonus: cyclistsBonus }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            await response.json();
            navigate("/profil");
        } catch (error) {
            console.error("Error updating bonus cyclist:", error);
        }
    };

    return (
        <main className="change-bonus-cyclist">
            <div className="change-bonus-cyclist-shadow-container">
                <div className="change-bonus-cyclist-title">
                    <h1>Choisir un coureur bonus</h1>
                    <div className="change-bonus-cyclist-shadow-mask-right"></div>
                    <div className="change-bonus-cyclist-shadow-mask-bottom"></div>
                </div>
            </div>
            <div className="change-bonus-cyclist-header">
                <div className="change-bonus-cyclist-rules">
                    <p >Sélectionnez votre cycliste bonus, ses points seront doublés.</p>
                </div>                
                <div className="change-bonus-cyclist-change-step">
                    <button onClick={handleSaveBonusCyclist}>VALIDER</button>
                </div>
            </div>
            
            <div className="change-bonus-cyclist-choice">
                <div className="change-bonus-cyclist-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>COUREUR</th>
                                <th>ÉQUIPE</th>
                                <th>NATION</th>
                                <th>VALEUR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedCyclists.filter(cyclist => cyclist.finalValue < 5).map((cyclist) => (
                                <tr key={cyclist.cyclistId} onClick={() => handleCyclistsBonus(cyclist)} className={isCyclistsBonusSelected(cyclist.cyclistId) ? "change-bonus-cyclist-table-selected-row" : ""}>
                                    <td>
                                        <div className="change-bonus-cyclist-name-info">
                                            <img 
                                                className="change-bonus-cyclist-flag-icon"
                                                src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality
                                                    .normalize("NFD")
                                                    .replace(/[\u0300-\u036f]/g, '')
                                                    .replace(/ /g, '_')
                                                    .toLowerCase()}.png`}                                                  
                                                alt={`Drapeau de ${cyclist.nationality}`} 
                                                width="20" 
                                                height="20"
                                            />
                                            <span>{cyclist.name}</span>
                                        </div>
                                    </td>
                                    <td>{cyclist.team}</td>
                                    <td>{cyclist.nationality}</td>
                                    <td>{cyclist.finalValue}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
};

export default ChangeBonusCyclist;
