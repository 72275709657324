import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "./styles/CreateTeamPage.css";

import worldTourBackground from "../medias/png/background/world_tour_background.png";
import neoProBackground from "../medias/png/background/neo_pro_background.png";

import SelectCyclists from "./createStep/SelectCyclists.js";
import SelectCyclistsBonus from "./createStep/SelectCyclistsBonus.js";
import SelectRaces from "./createStep/SelectRaces";
import SelectBonusRace from "./createStep/SelectBonusRace";
import createTeamConfig from "../config/createTeamConfig.json";

const CreateTeamPage = () => {
    const [step, setStep] = useState(1);
    const [budget, setBudget] = useState();
    const [numberPlayerSelected, setNumberPlayerSelected] = useState(0);
    const [selectedCyclists, setSelectedCyclists] = useState([]);
    const [cyclistsBonus, setCyclistsBonus] = useState([]);
    const [selectedRaces, setSelectedRaces] = useState([]);
    const [bonusRaces, setBonusRaces] = useState([]);
    const [races, setRaces] = useState([]);
    const [cyclists, setCyclists] = useState([]);
    const [teamId, setTeamId] = useState();
    const { user } = useAuth();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const today = new Date();
    const closeDate = new Date(createTeamConfig.closeDate);

    const createTeam = (teamId) => {
        setTeamId(teamId);
        nextStep();
    };

    const nextStep = () => {
        if (step < 5) {
            setStep(step + 1);
        }
    };

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    }; 

    const saveData = () => {
        return new Promise((resolve, reject) => {

            if (!user) {
                navigate("/logIn");
                reject("Utilisateur non connecté");
                return;
            }

            const obligatoryRaces = races.filter(race => race.groupCompetitionId === 0 || race.groupCompetitionId === 3);
            const playerTeamData = {
                selectedCyclists,
                cyclistsBonus,
                selectedRaces: [...obligatoryRaces, ...selectedRaces],
                bonusRaces, 
                teamId
            };

            fetch(`${apiUrl}/savePlayerTeam`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(playerTeamData)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                resolve(data);
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    };

    //useEffect
    useEffect(() => {
        if (!user) {
            navigate("/logIn");
            return;
        }

        const checkTeamExist = async () => {
            try {
                const response = await fetch(`${apiUrl}/userTeam/1`, {
                    credentials: "include"
                });

                if (!response.ok) {
                    throw new Error(`Erreur lors de la récupération des données de l'équipe: Statut ${response.status} - ${response.statusText}`);
                }

                const data = await response.json();

                if (data.selectedCyclists) {
                    navigate("/profil");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données de l'équipe:", error);
            }
        };

        if (today > closeDate) {
            checkTeamExist();
        }
    }, [apiUrl, user, navigate, closeDate, today]);

    useEffect(() => {
        if (!user) {
            navigate("/logIn");
            return;
        }         

        const fetchTeamData = async () => {
            try {

                const response = await fetch(`${apiUrl}/userTeam/${teamId}`, {
                    credentials: "include"
                });

                if (!response.ok) {
                    throw new Error(`Erreur lors de la récupération des données de l'équipe: Statut ${response.status} - ${response.statusText}`);
                }
                
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Erreur lors de la récupération des données de l'équipe:", error);
                throw error;
            }
        };
    
        const loadData = async () => {
            try {
                const teamDataExist = await fetchTeamData();

                if (teamDataExist) {
                    setSelectedCyclists(teamDataExist.selectedCyclists || []);
                    setCyclistsBonus(teamDataExist.cyclistsBonus || []);
                    setSelectedRaces(teamDataExist.selectedRaces || []);
                    setBonusRaces(teamDataExist.bonusRaces || []);
                    setNumberPlayerSelected(teamDataExist.selectedCyclists.length);
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error);
            }
        };
    
        if (teamId) {
            loadData();
        }
        
    }, [cyclists, apiUrl, user, navigate, teamId]);

    useEffect(() => {
        if (!user) {
            navigate("/logIn");
            return;
        }

        const fetchCyclists = async () => {
            try {
                const response = await fetch(`${apiUrl}/cyclists-list/${teamId}`);
                const data = await response.json();
                setCyclists(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des cyclistes:', error);
            }
        };

        const fetchRaces = async () => {
            try {
                const response = await fetch(`${apiUrl}/races-list-period`);
                const data = await response.json();
                
                const formattedData = data.map(race => ({
                    ...race,
                    date_start: new Date(race.date_start).toLocaleDateString("en-GB").replace(/\//g, '-')
                }));
                
                setRaces(formattedData);
            } catch (error) {
                console.error('Erreur lors de la récupération des courses:', error);
            }
        };        

        if (teamId) {
            fetchCyclists();
        }
        
        if (teamId === 1) {   
            fetchRaces();
        }

    }, [apiUrl, user, navigate, teamId]);

    useEffect(() => {
        if (!user) {
            navigate("/logIn");
            return;
        }  

        const calculateInitialBudget = (totalBudget) => {
            const totalValueOfSelectedCyclists = selectedCyclists.reduce((total, selectedCyclist) => {
                const cyclist = cyclists.find(c => c.cyclistId === selectedCyclist.cyclistId);
                return total + (cyclist ? cyclist.finalValue : 0);
            }, 0);
            
            return totalBudget - totalValueOfSelectedCyclists;
        };  

        const baseBudget = teamId === 1 ? 500 : 66;

        const initialBudget = calculateInitialBudget(baseBudget);
        setBudget(initialBudget);

    }, [apiUrl, user, navigate, selectedCyclists, cyclists, teamId]);

    switch(step) {
        case 1:
        return (
            <div className="create-team">
                <div className="create-team-shadow-container">
                    <div className="create-team-title">
                        <h1>CRÉER SON ÉQUIPE - CHOIX DES COUREURS</h1>
                        <div className="create-team-shadow-mask-right"></div>
                        <div className="create-team-shadow-mask-bottom"></div>
                    </div>
                </div>
                <div className="create-team-container">
                    <div className="create-team-left-container">
                        <img src={worldTourBackground} alt="Cycliste qui célèbre une victoire" width="630" height="360" loading="lazy" />
                        <button className="create-team-button" onClick={() => createTeam(1)}>WORLD TOUR</button>
                    </div>
                    <div className="create-team-right-container">
                        <img src={neoProBackground} alt="Jeune cycliste" width="550" height="360" loading="lazy" />
                        <button className="create-team-button" onClick={() => createTeam(2)}>NÉO PRO</button>
                    </div>
                </div>
            </div>
        );
        case 2:
        return (         
            <SelectCyclists 
                numberPlayerSelected={numberPlayerSelected}
                setNumberPlayerSelected={setNumberPlayerSelected}
                budget={budget} 
                setBudget={setBudget} 
                selectedCyclists={selectedCyclists} 
                setSelectedCyclists={setSelectedCyclists} 
                setCyclistsBonus={setCyclistsBonus}
                cyclistsBonus={cyclistsBonus}
                cyclists={cyclists}
                teamId={teamId}
                nextStep={nextStep} 
            />
        );
        case 3:
        return (
            <SelectCyclistsBonus 
                selectedCyclists={selectedCyclists} 
                setCyclistsBonus={setCyclistsBonus}
                cyclistsBonus={cyclistsBonus}
                nextStep={nextStep} 
                prevStep={prevStep}
                teamId={teamId}
                saveData={saveData}
            />
        );
        case 4:
        return (
            <SelectRaces 
                selectedRaces={selectedRaces} 
                setSelectedRaces={setSelectedRaces}
                setBonusRaces={setBonusRaces}
                bonusRaces={bonusRaces}
                races={races}
                nextStep={nextStep} 
                prevStep={prevStep}
            />
        );
        case 5:
        return (
            <SelectBonusRace 
                selectedRaces={selectedRaces} 
                setBonusRaces={setBonusRaces}
                bonusRaces={bonusRaces}
                races={races}
                prevStep={prevStep}
                saveData={saveData}
            />
        );
        default:
        return <div>Étape non reconnue</div>;
    }
}

export default CreateTeamPage;