import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "../styles/SelectBonusRaces.css";

import vallon from "../../medias/png/icons/vallon.png";
import chrono from "../../medias/png/icons/chrono.png";
import plat from "../../medias/png/icons/plat.png";
import montagne from "../../medias/png/icons/montagne.png";

const SelectBonusRace = ({ selectedRaces, setBonusRaces, bonusRaces, races, prevStep, saveData }) => {
    const icons = {
        Vallon: vallon,
        Chrono: chrono,
        Plat: plat,
        Montagne: montagne,
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const formattedRaces = selectedRaces.map(race => ({
        ...race,
        date_start: formatDate(race.date_start),
    }));

    const bonusEligibleRaces = races.filter(race => race.groupCompetitionId === 0);
    const combinedRaces = [...formattedRaces, ...bonusEligibleRaces];

    const sortedRaces = combinedRaces.sort((a, b) => {
        const parseDate = (dateString) => {
            const [day, month, year] = dateString.split('-').map(Number);
            return new Date(year, month - 1, day);
        };
        return parseDate(a.date_start) - parseDate(b.date_start);
    });

    const [confirmValidDataPopup, setConfirmValidDataPopup] = useState(false);
    const navigate = useNavigate(); 

    const handleSelectBonusRace = race => {
        const isRaceInBonusRaces = raceId => bonusRaces.some(br => br.raceId === raceId);

        if (isRaceInBonusRaces(race.raceId)) {
          setBonusRaces(bonusRaces.filter(br => br.raceId !== race.raceId));
        } else {
          setBonusRaces([...bonusRaces, race]);
        }
    };
    
    const isBonusRaceSelected = raceId => {
        return bonusRaces.some(br => br.raceId === raceId);
    };

    const validData = () => {
        saveData()
            .then(() => {
                setConfirmValidDataPopup(true);
            })
            .catch((error) => {
                console.error("Erreur lors de l'enregistrement :", error);
            });
    };

    const handleClosePopup = () => {
        navigate("/profil");
    };

    return (
        <main className="select-races-bonus">
            {
                confirmValidDataPopup && (
                    <div className="select-races-bonus-popup">
                        <div className="select-races-bonus-popup-content">
                            <p>Félicitation !<br />Vos choix d'équipe ont bien été pris en compte.</p>
                            <button onClick={handleClosePopup}>Valider</button>
                        </div>
                    </div>
                )
            }
            <div className="select-races-bonus-shadow-container">
                <div className="select-races-bonus-title">
                    <h1>CRÉER SON ÉQUIPE - CHOIX DES OBJECTIFS DE SAISON</h1>
                    <div className="select-races-bonus-shadow-mask-right"></div>
                    <div className="select-races-bonus-shadow-mask-bottom"></div>
                </div>
            </div>
            <div className="select-races-bonus-header">
                <div className="select-races-bonus-rules">
                    <p >Sélectionnez vos courses bonus : leurs points points compteront double.</p>
                </div>
                <div className="select-races-bonus-change-step">
                    <button onClick={prevStep}>PRÉCÉDENT</button>
                    <button 
                        onClick={validData} 
                        disabled={
                            bonusRaces.filter(race => race.groupCompetitionId === 0).length > 3 || 
                            bonusRaces.filter(race => race.groupCompetitionId === 1).length > 3 || 
                            bonusRaces.filter(race => race.groupCompetitionId === 2).length > 3
                        }
                    >
                        VALIDER
                    </button>
                </div>  
            </div>
            <div className="select-races-bonus-pannel-view">
                <div className="select-races-bonus-choice">
                    <div className="select-races-bonus-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>COURSES</th>
                                    <th>DATE</th>
                                    <th>CATÉGORIE</th>
                                    <th>DIVISION</th>
                                    <th>TYPE DE COURSE</th>
                                    <th>PROFIL</th>
                                    <th>NOMBRE D'ÉTAPES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedRaces.map((race) => (
                                    <tr key={race.raceId} onClick={() => handleSelectBonusRace(race)} className={isBonusRaceSelected(race.raceId) ? "select-races-bonus-table-selected-row" : ""}>
                                        <td>
                                            <div className="select-races-bonus-table-name-container">
                                                <img 
                                                    className="select-races-table-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${race.country
                                                        .normalize("NFD")
                                                        .replace(/[\u0300-\u036f]/g, '')
                                                        .replace(/ /g, '_')
                                                        .toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${race.country}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <span>{race.name}</span>
                                            </div>
                                        </td>
                                        <td>{race.date_start}</td>
                                        <td>{race.category}</td>
                                        <td>{race.groupCompetitionId === 0 ? "World Tour" : race.groupCompetitionId === 1 ? "Continental" : race.groupCompetitionId === 2 ? "Pro Series" : "International"}</td>
                                        <td>{race.courseType}</td>
                                        <td>
                                            {icons[race.profil] ? (
                                                <img src={icons[race.profil]} alt={race.profil} width="25" height="25" />
                                            ) : (
                                                race.profil
                                            )}
                                        </td>
                                        <td>{race.step}</td>  
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="select-races-bonus-choice-view">
                    <p className="select-races-bonus-selected-number">
                        Courses World Tour : 
                        <span className={bonusRaces.filter(race => race.groupCompetitionId === 0).length === 3 ? "select-races-bonus-selected-races-green" : "select-races-bonus-selected-races-red"}>
                            {bonusRaces.filter(race => race.groupCompetitionId === 0).length}
                        </span>/3
                    </p>
                    <p className="select-races-bonus-selected-number">
                        Courses Continental : 
                        <span className={bonusRaces.filter(race => race.groupCompetitionId === 1).length === 3 ? "select-races-bonus-selected-races-green" : "select-races-bonus-selected-races-red"}>
                            {bonusRaces.filter(race => race.groupCompetitionId === 1).length}
                        </span>/3
                    </p>
                    <p className="select-races-bonus-selected-number">
                        Courses Pro Series : 
                        <span className={bonusRaces.filter(race => race.groupCompetitionId === 2).length === 3 ? "select-races-bonus-selected-races-green" : "select-races-bonus-selected-races-red"}>
                            {bonusRaces.filter(race => race.groupCompetitionId === 2).length}
                        </span>/3
                    </p>
                    <div className="select-races-bonus-scroll-container">
                        <div className="select-races-bonus-line-container">
                            {bonusRaces
                                .filter((_, index) => index % 2 === 0)
                                .map(race => (
                                    <div className="select-races-bonus-selected-cell" key={race.raceId}>            
                                        <div className="select-races-bonus-selected-info">
                                            <img src={`${process.env.PUBLIC_URL}/icons/cancel.png`} alt="Icone de suppression" width="17" height="17" onClick={() => handleSelectBonusRace(race)} />
                                            <div className="select-races-bonus-selected-name-container">
                                                <img 
                                                    className="select-races-bonus-selected-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${race.country.replace(/ /g, '_').toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${race.country}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <h4>{race.name}</h4>
                                            </div>
                                            <p>{race.category}</p>
                                            <p>{race.groupCompetitionId === 0 ? "World Tour" : race.groupCompetitionId === 1 ? "Continental" : race.groupCompetitionId === 2 ? "Pro Series" : "International"}</p>
                                            <p>
                                                {icons[race.profil] ? (
                                                    <img src={icons[race.profil]} alt={race.profil} width="35" height="25" />
                                                ) : (
                                                    race.profil
                                                )}
                                            </p>
                                        </div>
                                    </div>
                            ))}
                        </div>
                        <div className="select-races-bonus-line-container">
                            {bonusRaces
                                .filter((_, index) => index % 2 === 1)
                                .map(race => (
                                    <div className="select-races-bonus-selected-cell" key={race.raceId}>            
                                        <div className="select-races-bonus-selected-info">
                                            <img src={`${process.env.PUBLIC_URL}/icons/cancel.png`} alt="Icone de suppression" width="17" height="17" onClick={() => handleSelectBonusRace(race)} />
                                            <div className="select-races-bonus-selected-name-container">
                                                <img 
                                                    className="select-races-bonus-selected-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${race.country.replace(/ /g, '_').toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${race.country}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <h4>{race.name}</h4>
                                            </div>
                                            <p>{race.category}</p>
                                            <p>{race.groupCompetitionId === 0 ? "World Tour" : race.groupCompetitionId === 1 ? "Continental" : race.groupCompetitionId === 2 ? "Pro Series" : "International"}</p>
                                            <p>
                                                {icons[race.profil] ? (
                                                    <img src={icons[race.profil]} alt={race.profil} width="35" height="25" />
                                                ) : (
                                                    race.profil
                                                )}
                                            </p>
                                        </div>
                                    </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>     
        </main>
    );
}

export default SelectBonusRace;