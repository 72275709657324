import React, { useState } from "react";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

import "../styles/Ranking.css";

const Ranking = ({ generalRankings, espoirRankings }) => {
    const { userId } = useAuth();
    const [selectedTeam, setSelectedTeam] = useState(1);
    const [ranking, setRanking] = useState("general");
    const navigate = useNavigate();

    const handleButtonClick = (teamId) => {
        setSelectedTeam(teamId);
    };

    const handleRankingButtonClick = (ranking) => {
        setRanking(ranking);
    };

    const handleRowClick = (userId) => {
        navigate(`/userTeam/${userId}`);
    };

    const getDisplayedRankings = () => {
        const rankings = selectedTeam === 1 ? generalRankings : espoirRankings;

        if (ranking === "general") {
            return rankings;
        }

        const seasonKey = `${ranking}Points`;
        return [...rankings].sort((a, b) => b[seasonKey] - a[seasonKey]);
    };

    const displayedRankings = getDisplayedRankings();

    return (
        <section className="my-ranking">
            <div className="my-ranking-choice">
                <button
                    onClick={() => handleButtonClick(1)}
                    style={{ backgroundColor: selectedTeam === 1 ? "#007afd" : "#0056b3" }}
                >
                    GÉNÉRAL
                </button>
                <button
                    onClick={() => handleButtonClick(2)}
                    style={{ backgroundColor: selectedTeam === 2 ? "#007afd" : "#0056b3" }}
                >
                    ESPOIR
                </button>
            </div>
            <div className="my-ranking-choice-ranking">
                <button
                    onClick={() => handleRankingButtonClick("general")}
                    style={{ backgroundColor: ranking === "general" ? "#007afd" : "#0056b3" }}
                >
                    GÉNÉRAL
                </button>
                <button
                    onClick={() => handleRankingButtonClick("winter")}
                    style={{ backgroundColor: ranking === "winter" ? "#007afd" : "grey" }}
                >
                    HIVER
                </button>
                <button
                    onClick={() => handleRankingButtonClick("spring")}
                    style={{ backgroundColor: ranking === "spring" ? "#007afd" : "pink" }}
                >
                    PRINTEMPS
                </button>
                <button
                    onClick={() => handleRankingButtonClick("summer")}
                    style={{ backgroundColor: ranking === "summer" ? "#007afd" : "green" }}
                >
                    ÉTÉ
                </button>
                <button
                    onClick={() => handleRankingButtonClick("automn")}
                    style={{ backgroundColor: ranking === "automn" ? "#007afd" : "orange" }}
                >
                    AUTOMNE
                </button>
            </div>
            <div className="my-ranking-table">
                <div className="my-ranking-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>POSITION</th>
                                <th>NOM DU JOUEUR</th>
                                <th>POINTS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedRankings.map((entry, index) => (
                                <tr
                                    key={index}
                                    className={entry.userId === userId ? "highlighted" : ""}
                                    onClick={() => handleRowClick(entry.userId)}
                                >
                                    <td>{index + 1}</td>
                                    <td>{entry.name}</td>
                                    <td>
                                        {ranking === "general"
                                            ? entry.total_points
                                            : entry[`${ranking}Points`]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Ranking;
