import React from "react";

import "./styles/Stats.css";

import graphics1 from "../medias/png/graphics/1.png";
import graphics2 from "../medias/png/graphics/2.png";
import graphics3 from "../medias/png/graphics/3.png";
import graphics4 from "../medias/png/graphics/4.png";
import graphics5 from "../medias/png/graphics/5.png";
import graphics6 from "../medias/png/graphics/6.png";

const Stats = () => {

    return (
        <main className="stats">
            <h1><br />STATISTIQUES</h1>
            <p>Les statistiques vous permettent d4avoir une meilleure vision des performances coureurs mais aussi de leurs popularités auprès des joueurs.</p>
            <div className="stats-graphics-grid">
                <img src={graphics1} alt="Graphic 1" />
                <img src={graphics2} alt="Graphic 2" />
                <img src={graphics3} alt="Graphic 3" />
                <img src={graphics4} alt="Graphic 4" />
                <img src={graphics5} alt="Graphic 5" />
                <img src={graphics6} alt="Graphic 6" />
            </div>            
        </main>
    );
};

export default Stats;