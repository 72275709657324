import { useAuth } from "./AuthContext";
import { Navigate, Route, Routes } from "react-router-dom";

import CreateTeamPage from "./components/CreateTeamPage";
import ChangeTeamPage from "./components/ChangeTeamPage";
import Profil from "./components/Profil";
import Ranking from "./components/ProfilView/Ranking";
import UserTeam from "./components/ProfilView/UserTeam";
import CyclistDetails from "./components/ProfilView/CyclistDetails";
import SetRaceRanking from "./components/SetRaceRanking";
import ChangeBonusCyclist from "./components/ChangeBonusCyclist";

import createTeamConfig from "./config/createTeamConfig.json";

const isTodayFirstOfMonth = () => {
    const today = new Date("2024-01-01");
    return today.getDate() === 1;
};

const isTodayChangeableDate = () => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
  
    const changeableDate1 = new Date(createTeamConfig.changeableDate1).toISOString().split('T')[0];
    const changeableDate2 = new Date(createTeamConfig.changeableDate2).toISOString().split('T')[0];
    const changeableDate3 = new Date(createTeamConfig.changeableDate3).toISOString().split('T')[0];
    const changeableDate4 = new Date(createTeamConfig.changeableDate4).toISOString().split('T')[0];
    const changeableDate5 = new Date(createTeamConfig.changeableDate5).toISOString().split('T')[0];
    const changeableDate6 = new Date(createTeamConfig.changeableDate6).toISOString().split('T')[0];
  
    return formattedToday === changeableDate1 || formattedToday === changeableDate2 || formattedToday === changeableDate3 || formattedToday === changeableDate4 || formattedToday === changeableDate5 || formattedToday === changeableDate6;
};

const ProtectedRoutes = () => {
    const { user, isLoading, accountType } = useAuth();

    if (isLoading) {
        return <div>Chargement...</div>;
    }

    const createTeamElement = !user ? <Navigate to="/login" /> : <CreateTeamPage />;
    const changeTeamElement = !user ? <Navigate to="/login" /> : isTodayChangeableDate() ? <ChangeTeamPage /> : <Navigate to="/" />;
    const changeBonusCyclistElement = !user ? <Navigate to="/login" /> : isTodayFirstOfMonth() ? <ChangeBonusCyclist /> : <Navigate to="/" />;

    return (
        <Routes>
            <Route path="/profil" element={user ? <Profil /> : <Navigate to="/login" />} />
            <Route path="/createTeam" element={createTeamElement} />
            <Route path="/changeTeam" element={changeTeamElement} />
            <Route path="/userTeam/:userId" element={user ? <UserTeam /> : <Navigate to="/login" />} />
            <Route path="/cyclistDetails/:cyclistId" element={user ? <CyclistDetails /> : <Navigate to="/login" />} />
            <Route path="/ranking/" element={user ? <Ranking /> : <Navigate to="/login" />} />
            <Route path="/set-race-ranking" element={accountType === 'admin' ? <SetRaceRanking /> : <Navigate to="/" />} />
            <Route path="/changeBonusCyclist/" element={changeBonusCyclistElement} />
        </Routes>
    );
};

export default ProtectedRoutes;
