import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import Select from 'react-select';

import "./styles/SetRaceRanking.css";

const CyclistSelectorTable = ({ cyclists, onSelectionChange }) => {
    const cyclistOptions = cyclists.map((cyclist) => ({
        cyclistId: cyclist.cyclistId,
        label: cyclist.name,
    }));

    const [selections, setSelections] = useState(Array(50).fill(null));

    const handleSelectionChange = (selectedOption, position) => {
        const updatedSelections = [...selections];
        updatedSelections[position] = selectedOption;
        setSelections(updatedSelections);

        const selectionsToSend = updatedSelections
            .map((selection, index) => selection ? { ...selection, position: index + 1 } : null)
            .filter((selection) => selection !== null);

        onSelectionChange(selectionsToSend);
    };

    return (
        <div className="set-race-ranking-select-cyclist">
            <h2>Classement de la Course</h2>
            <table>
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Nom du Coureur</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: 50 }, (_, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <Select
                                    value={selections[index]}
                                    onChange={(selectedOption) => handleSelectionChange(selectedOption, index)}
                                    options={cyclistOptions}
                                    placeholder="Sélectionnez un cycliste"
                                    isClearable={true}
                                    isSearchable={true}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const RaceSelector = ({ races, onRaceSelect }) => {
    const [selectedOption, setSelectedOption] = useState(null);
  
    const options = races.map(race => ({
      value: race.raceId,
      label: race.name
    }));
  
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onRaceSelect(selectedOption.value);
    };
  
    return (
      <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder="Rechercher une course..."
            className="set-race-ranking-select-race"
      />
    );
  };
  
const RaceDetails = ({ race }) => {
    if (!race) return <div>Sélectionnez une course à mettre à jour</div>;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="set-race-ranking-choose-race">
            <p>Nom: {race.name}</p>
            <p>Étape: {race.step}</p>
            <p>Date de début: {formatDate(race.date_start)}</p>
            <p>Date de fin: {formatDate(race.date_end)}</p>
            <p>Catégorie: {race.category}</p>
        </div>
    );
};

const SetRaceRanking = () => {
    const [races, setRaces] = useState([]);
    const [choseRace, setChoseRace] = useState(null);
    const [raceStep, setRaceStep] = useState(0);
    const [cyclists, setCyclists] = useState([]);
    const [cyclistsList, setCyclistsList] = useState([]);
    const [selectedSortValue, setSelectedSortValue] = useState("value");
    const [selectedCyclists, setSelectedCyclists] = useState([]);
    const [updateMessage, setUpdateMessage] = useState("");
    const { user, accountType } = useAuth();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const apiUrl = process.env.REACT_APP_API_URL;

    //useEffect
    useEffect(() => {
        if (accountType !== "admin") {
            navigate("/");
            return;
        }

        //Get Cyclists List function
        const fetchCyclists = async () => {
            try {
                const response = await fetch(`${apiUrl}/cyclists-list/1`);
                const data = await response.json();
                setCyclists(data);
                setCyclistsList(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des cyclistes:', error);
            }
        };

        //Get Races List function
        const fetchRaces = async () => {
            try {
                const response = await fetch(`${apiUrl}/races-list`);
                const data = await response.json();
                setRaces(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des courses:', error);
            }
        };

        fetchCyclists();    
        fetchRaces();

    }, [apiUrl, navigate, accountType]);

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    
        if (newSearchTerm) {
            const filteredCyclists = cyclists.filter(cyclist =>
                cyclist.name.toLowerCase().startsWith(newSearchTerm.toLowerCase())
            );
            sortCyclists(selectedSortValue, filteredCyclists);
        } else {
            sortCyclists(selectedSortValue, cyclists);
        }
    };

    const handleSortChange = (event) => {
        const sortBy = event.target.value;
        setSelectedSortValue(sortBy);

        sortCyclists(sortBy, cyclistsList);
    };

    const sortCyclists = (sortBy, sortedCyclists) => {
        switch (sortBy) {
            case "value":
                sortedCyclists.sort((a, b) => b.finalValue - a.finalValue);
                break;
            case "nation":
                sortedCyclists.sort((a, b) => a.nationality.localeCompare(b.nationality));
                break;
            case "team":
                sortedCyclists.sort((a, b) => a.team.localeCompare(b.team));
                break;
            default:
        }
        setCyclistsList(sortedCyclists);
    };

    const handleRaceSelect = (raceId) => {
        const race = races.find(r => r.raceId === raceId);
        setChoseRace(race);
    };

    const handleCyclistSelectionChange = (selections) => {
        setSelectedCyclists(selections);
    };

    const updateRanking = async () => {
        if (accountType !== "admin") {
            navigate("/");
            return;
        }

        const ranking = { selectedCyclists, raceStep, choseRace };

        fetch(`${apiUrl}/updateRanking`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(ranking)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log("Success:", data);
            setUpdateMessage("Classement mis à jour");
        })
        .catch((error) => {
            console.error("Error:", error);
            setUpdateMessage(`Échec, classement non mis à jour : ${error}`);
        });
    };

    const disabled = choseRace === null || raceStep === 0 || selectedCyclists.length === 0;

    return(
        <>
            {user && accountType === "admin" && (
                <main className="set-race-ranking">
                    <div className="set-race-ranking-shadow-container">
                        <div className="set-race-ranking-title">
                            <h1>Ajouter un classement</h1>
                            <div className="set-race-ranking-shadow-mask-right"></div>
                            <div className="set-race-ranking-shadow-mask-bottom"></div>
                        </div>
                        
                    </div>

                    <div className="set-race-ranking-button-container">
                        <button
                            className={`votreClasseDeBase ${disabled ? 'button-disabled' : ''}`}
                            disabled={disabled}
                            onClick={updateRanking}
                        >
                            Valider les positions et mettre à jour le classement
                        </button>
                        {updateMessage && (<p>{updateMessage}</p>)}
                    </div>


                    <RaceSelector races={races} onRaceSelect={handleRaceSelect} />
                    <RaceDetails race={choseRace} />

                    <div className="set-race-ranking-choice-step">
                        <p>Veuillez indiquer l'étape (1000 pour le classement général)</p>
                        <input onChange={(e) => setRaceStep(e.target.value)}/>
                    </div>

                    <div className="set-race-ranking-ranking-view">
                        <CyclistSelectorTable
                            cyclists={cyclists}
                            onSelectionChange={handleCyclistSelectionChange}
                        />

                        <div className="set-race-ranking-cyclists-view">
                            <div className="set-race-ranking-controls">
                                <div className="set-race-ranking-sort-control">
                                    <label>Trier par</label>
                                    <select onChange={handleSortChange}>
                                        <option value="value">Valeur</option>
                                        <option value="nation">Nation</option>
                                        <option value="team">Équipe</option>
                                    </select>
                                </div>
                                <div className="set-race-ranking-search-control">
                                    <img src={`${process.env.PUBLIC_URL}/icons/loupe.png`} alt="Icone d'une loupe" width="22" height="22" className="set-race-ranking-search-icon" />
                                    <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Rechercher par nom" />
                                </div>
                            </div>
                            <div className="set-race-ranking-table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>COUREUR</th>
                                            <th>ÉQUIPE</th>
                                            <th>NATION</th>
                                            <th>VALEUR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cyclistsList.map((cyclist) => (
                                            <tr key={cyclist.cyclistId}>
                                                <td>
                                                    <div className="set-race-ranking-name-info">
                                                        <img 
                                                            className="set-race-ranking-flag-icon"
                                                            src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality
                                                                .normalize("NFD")
                                                                .replace(/[\u0300-\u036f]/g, '')
                                                                .replace(/ /g, '_')
                                                                .toLowerCase()}.png`} 
                                                            alt={`Drapeau de ${cyclist.nationality}`} 
                                                            width="20" 
                                                            height="20"
                                                        />
                                                        <span>{cyclist.name}</span>
                                                    </div>
                                                </td>
                                                <td>{cyclist.team}</td>
                                                <td>{cyclist.nationality}</td>
                                                <td>{cyclist.finalValue}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>  
                    </div>                      
                </main>
            )}
        </>
    );
}

export default SetRaceRanking;