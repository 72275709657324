import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./styles/HomePage.css";

import homeBackground from "../medias/png/background/home_background.png";
import homeBackgroundMobile from "../medias/png/background/home_background_mobile.png";
import homeCyclistZoom from "../medias/png/background/home_cyclist_zoom.png";
import homeTeam1 from "../medias/png/background/home-team1.png";
import homeTeam2 from "../medias/png/background/home-team2.png";
import homeTeam3 from "../medias/png/background/home-team3.png";
import homeTeam4 from "../medias/png/background/home-team4.png";
import homeTeam5 from "../medias/png/background/home-team5.png";

const HomePage = () => {
    const [isMobile] = useState(window.innerWidth <= 699);

    return (
        <main className="home">
            <section className="home-section1">
                <div className="home-section1-background">
                    <img src={isMobile ? homeBackgroundMobile : homeBackground} className="home-section1-background-image" alt="Gros plan cycliste" loading="lazy" />
                </div>
            </section>

            <section className="home-section2">
                <div className="home-section2-left-container">
                    <div className="home-section2-text-content">
                        <h1>BIENVENUE SUR L'ÉPOPÉE</h1>
                        <h2>Votre jeu fantasy crée par Vélofuté</h2>
                        <p>
                            Plongez dans l'univers captivant du cyclisme avec notre jeu fantasy de gestion d'équipe en<br />
                            ligne, gratuit !<br />
                            Defis les meilleurs joueurs durant l'intégralite de la saison cycliste professionnelle.
                        </p>
                        <Link to="/signup">
                            <button className="home-section2-button">JE M'INSCRIS {'>'}</button>
                        </Link>
                    </div>
                </div>
                <div className="home-section2-right-container">
                    <img src={homeCyclistZoom} alt="Gros plan sur cycliste" className="home-section2-right-image" loading="lazy" />
                </div>
            </section>

            <section className="home-section3">
                {/* Section 1: Crée ton équipe */}
                <div className="home-section3-container-create-team">
                    <div className="home-section3-left-create-team">
                        <img
                            src={homeTeam1}
                            alt="Cycliste"
                            className="home-section3-left-image-create-team"
                        />
                    </div>
                    <div className="home-section3-content-create-team">
                        <h2 className="home-section3-title-create-team">CRÉE TON ÉQUIPE</h2>
                        <p>
                            Avec un <strong>budget de 500M</strong>, crée ton équipe sans aucune contrainte. Attention, ton choix est définitif ! Tu n'auras que <strong>quelques transferts</strong> durant l'année pour ajuster ton effectif.
                        </p>
                        <p>
                            Choisis bien tes coureurs en fonction de tes choix de courses.
                        </p>
                    </div>
                    <div className="home-section3-right-create-team">
                        <img
                            src={homeTeam2}
                            alt="Calendrier"
                            className="home-section3-right-image-create-team"
                        />
                    </div>
                </div>

                {/* Section 2: Choisi ton calendrier */}
                <div className="home-section3-container-choose-calendar blue-bg">
                    <div className="home-section3-left-choose-calendar">
                        <img
                            src={homeTeam3}
                            alt="Cycliste"
                            className="home-section3-left-image-choose-calendar"
                        />
                    </div>
                    <div className="home-section3-content-choose-calendar">
                        <h2 className="home-section3-title-choose-calendar">CHOISI TON CALENDRIER</h2>
                        <p>
                            En tant que DS, vous devrez <strong>sélectionner un certain nombre de courses</strong> auxquelles vous allez participer en <strong>Pro Series</strong> et <strong>Continental</strong>. Pour les moins aguerris, il existe des calendriers par défauts pour vous aider.
                        </p>
                        <p>
                            À noter que vous êtes <strong>invités</strong> automatiquement sur toutes <strong>les courses WT</strong>.
                        </p>
                    </div>
                </div>

                {/* Section 3: Collectionne les emblèmes */}
                <div className="home-section3-container-collect-badges">
                    <div className="home-section3-content-collect-badges">
                        <img
                            src={homeTeam4}
                            alt="Cycliste"
                            className="home-section3-left-image-collect-badges"
                        />
                        <div className="home-section3-badge">NOUVEAUTÉ</div>
                        <h2 className="home-section3-title-collect-badges">COLLECTIONNE LES EMBLÈMES</h2>
                        <p>
                            <strong>Nouveauté</strong> cette année, les emblèmes font leur apparition pour rendre la saison plus dynamique et rajouter de nouveaux objectifs aux joueurs. Ces emblèmes sont scindés en 4 catégories :
                        </p>
                        <p><strong>Récompense - Challenges - Effectif - Le Spécialiste</strong></p>
                    </div>

                    <div className="home-section3-right-collect-badges">
                        <img
                            src={homeTeam5}
                            alt="Cycliste et emblèmes"
                            className="home-section3-right-image-collect-badges"
                        />
                    </div>
                </div>

            </section>

        </main>
    );
}

export default HomePage;