import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/MyRaces.css";

import cible from "../../medias/png/icons/cible.png";

const MyRaces = ({ races, today }) => {
    const navigate = useNavigate();

    const handleRowClick = (raceId) => {
        navigate(`/show-race-detail/${raceId}`);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    //const today = new Date();
    today.setHours(0, 0, 0, 0);
    const filteredRaces = races.filter((race) => new Date(race.date_start) >= today);

    return (
        <section className="my-races">
            <h2>Mes prochaines courses</h2>
            <div className="my-races-table">
                <div className="my-races-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>COURSES</th>
                                <th>DATE</th>
                                <th>CATÉGORIE</th>
                                <th>DIVISION</th>
                                <th>TYPE DE COURSE</th>
                                <th>PROFIL</th>
                                <th>NOMBRE D'ÉTAPES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRaces.length > 0 ? (
                                filteredRaces.map((race) => (
                                    <tr key={race.raceId} onClick={() => handleRowClick(race.raceId)}>
                                        <td>
                                            <div className="my-races-name-container">
                                                {race.isBonus === 1 && <img src={cible} alt="Target Icon" className="my-races-name-container-image" />}
                                                <img 
                                                    className="select-races-table-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${race.country
                                                        .normalize("NFD")
                                                        .replace(/[\u0300-\u036f]/g, '')
                                                        .replace(/ /g, '_')
                                                        .toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${race.country}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <span>{race.name}</span>
                                            </div>
                                        </td>
                                        <td>{formatDate(race.date_start)}</td>
                                        <td>{race.category}</td>
                                        <td>{race.groupCompetitionId === 0 ? "World Tour" : race.groupCompetitionId === 1 ? "Continental" : race.groupCompetitionId === 2 ? "Pro Series" : "International"}</td>
                                        <td>{race.courseType}</td>
                                        <td>{race.category}</td>
                                        <td>{race.step}</td>   
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: "center" }}>
                                        Aucune course à venir
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default MyRaces;
