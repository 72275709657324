import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";

import "../styles/cyclistDetail.css";

const CyclistDetail = () => {  
    const navigate = useNavigate();
    const { user } = useAuth();
    const params = useParams();
    const [races, setRaces] = useState([]);
    const [name, setName] = useState();
    const cyclistId = params.cyclistId;
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchCyclists = async () => {
            if (!user) {
                navigate("/logIn");
                return;
            }
    
            try {
                const response = await fetch(`${apiUrl}/cyclist-details/${cyclistId}`, {
                    credentials: "include",
                });
    
                if (!response.ok) {
                    setRaces([]);
                    throw new Error("Erreur lors de la récupération des cyclistes");
                }
    
                const data = await response.json();
    
                setRaces(data);
                setName(data[0]?.cyclistName);
            } catch (error) {
                console.error("Erreur:", error);
                setRaces([]);
                setName(undefined);
            }
        };
    
        fetchCyclists();
    }, [apiUrl, user, navigate, cyclistId]);
     

    return (
        <section className="cyclistDetail">
            <div className="cyclistDetail-link-back-to-ranking-container">
                <Link to="/profil?section=MyTeam" className="cyclistDetail-link-back-to-ranking">Retour à l'équipe</Link>
            </div>
            {name && <h1>{name}</h1>}
            {races.length > 0 ? (
                <div className="cyclistDetail-table">
                    <div className="cyclistDetail-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>COURSE</th>
                                    <th>DATE</th>
                                    <th>POSITION</th>
                                    <th>POINTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {races.map((race) => (
                                    <tr key={race.raceId} >
                                        <td>
                                            <div className="cyclistDetail-name-info">
                                                <img 
                                                    className="select-races-table-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${race.country
                                                        .normalize("NFD")
                                                        .replace(/[\u0300-\u036f]/g, '')
                                                        .replace(/ /g, '_')
                                                        .toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${race.country}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <span>{race.name}</span>
                                            </div>
                                        </td>
                                        <td>{new Date(race.date_start).toLocaleDateString()}</td>
                                        <td>{race.position}</td>
                                        <td>{race.points}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <p>Aucune course trouvé pour ce cycliste.</p>
            )}
        </section>
    );
};

export default CyclistDetail;