import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import "../styles/MyProfile.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MyProfile = ({ emblems, generalCyclists }) => {
    const emblemes = require.context("../../medias/embleme", false, /\.png$/);
    const images = Array.from({ length: 129 }, (_, index) =>
        emblemes(`./${index + 1}.png`)
    );

    const emblemes1 = require.context("../../medias/embleme1", false, /\.png$/);
    const images1 = Array.from({ length: 129 }, (_, index) =>
        emblemes1(`./${index + 1}.png`)
    );

    const [view, setView] = useState("both");
    const [fullscreenImage, setFullscreenImage] = useState(null);

    const handleImageClick = (src) => {
        setFullscreenImage(src);
    };

    const closeFullscreen = () => {
        setFullscreenImage(null);
    };

    const categorizedEmblems = [0, 1, 2, 3].map((category) =>
        emblems.filter((emblem) => emblem.category === category)
    );

    const specialityCounts = generalCyclists.reduce((acc, cyclist) => {
        acc[cyclist.speciality] = (acc[cyclist.speciality] || 0) + 1;
        return acc;
    }, {});

    const data = {
        labels: ["Baroudeur", "Sprinteur", "Classicman", "Contre-la-montre", "Polyvalent", "Course à étapes"],
        datasets: [
            {
                label: "Nombre de cyclistes",
                data: [
                    specialityCounts["Baroudeur"] || 0,
                    specialityCounts["Sprinteur"] || 0,
                    specialityCounts["Classicman"] || 0,
                    specialityCounts["Contre-la-montre"] || 0,
                    specialityCounts["Polyvalent"] || 0,
                    specialityCounts["Course à étapes"] || 0,
                ],
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            }
        },
    };

    return (
        <div className="my-profiles">
            {fullscreenImage && (
                <div className="my-profiles-fullscreen-container" onClick={closeFullscreen}>
                    <img
                        src={fullscreenImage}
                        alt="Fullscreen Emblem"
                        className="my-profiles-fullscreen-image"
                    />
                    <div className="my-profiles-close-button" onClick={closeFullscreen}>
                        ✖
                    </div>
                </div>
            )}

            <div
                className={`my-profiles-left ${view === "left" ? "expanded" : ""} ${
                    view === "right" ? "hidden" : ""
                }`}
            >
                <h2>
                    EMBLÈMES{" "}
                    <span onClick={() => setView(view === "left" ? "both" : "left")}>
                        +
                    </span>
                </h2>
                <p>
                    Les emblèmes reflètent et illustrent vos performances, vos choix et
                    stratégies durant la saison. Collectez-en un maximum pour devenir le
                    meilleur joueur de l'Épopée.
                </p>
                <div className="my-profiles-rows">
                    {["Récompense", "Challenges", "Spécialistes", "Effectifs"].map(
                        (categoryName, categoryIndex) => (
                            <div key={categoryIndex} className="my-profiles-row">
                                <strong>{categoryName}</strong>
                                <div className="my-profiles-icons">
                                    {categorizedEmblems[categoryIndex].map((emblem) => (
                                        <img
                                            key={emblem.emblemId}
                                            src={images1[emblem.emblemId - 1]}
                                            alt={`Emblem ${emblem.emblemId}`}
                                            onClick={() =>
                                                handleImageClick(
                                                    images[emblem.emblemId - 1]
                                                )
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>

            <div
                className={`my-profiles-right ${view === "right" ? "expanded" : ""} ${
                    view === "left" ? "hidden" : ""
                }`}
            >
                <h2>
                    GRAPHICS{" "}
                    {/*<span onClick={() => setView(view === "right" ? "both" : "right")}>
                        +
                    </span>*/}
                </h2>
                {view !== "right" ? (
                    <Bar data={data} options={options} />
                ) : (
                    <div className="graphics-grid">
                    </div>
                )}
            </div>
        </div>
    );
};

export default MyProfile;
