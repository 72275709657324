import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";

import "../styles/UserTeam.css";

const UserTeam = () => {  
    const navigate = useNavigate();
    const { user } = useAuth();
    const params = useParams();
    const [selectedTeam, setSelectedTeam] = useState(1);
    const [sortKey, setSortKey] = useState('value');
    const [cyclists, setCyclists] = useState([]);
    const [pseudo, setPseudo] = useState("");
    const userId = params.userId;
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleRowClick = (cyclistId) => {
        navigate(`/cyclistDetails/${cyclistId}`);
    };

    useEffect(() => {
        const fetchCyclists = async () => {
            if (!user) {
                navigate("/logIn");
                return;
            }
            
            try {
                const response = await fetch(`${apiUrl}/cyclists-userteam/${userId}`, {
                    credentials: "include"
                });
                
                if (!response.ok) {
                    setCyclists([]);
                    throw new Error("Erreur lors de la récupération des cyclistes");
                }

                const data = await response.json();
                setCyclists(data.cyclists);
                setPseudo(data.pseudo);
                
            } catch (error) {
                console.error("Erreur:", error);
            }
        };

        fetchCyclists();

    }, [apiUrl, user, navigate, userId]);

    const getSortedCyclists = () => {
        return cyclists
            .filter(cyclist => cyclist.teamId === selectedTeam)
            .sort((a, b) => {
                switch (sortKey) {
                    case "value":
                        return b.finalValue - a.finalValue;
                    case "points":
                        return b.cyclistPoints - a.cyclistPoints;
                    default:
                        return 0;
                }
            });
    };

    const handleSortChange = (event) => {
        setSortKey(event.target.value);
    };

    const handleButtonClick = (buttonName) => {
        setSelectedTeam(buttonName);
    };

    const sortedCyclists = getSortedCyclists();

    return (
        <section className="userteam">
            <div className="userteam-link-back-to-ranking-container">
                <Link to="/profil?section=Ranking" className="userteam-link-back-to-ranking">Retour au classement</Link>
            </div>
            {pseudo && <h1>Équipe de {pseudo}</h1>}
            <div className="userteam-choice">
                <button
                    onClick={() => handleButtonClick(1)}
                    style={{ backgroundColor: selectedTeam === 1 ? "#0056b3" : "#007afd" }}
                >
                    WORLD TOUR
                </button>
                <button
                    onClick={() => handleButtonClick(2)}
                    style={{ backgroundColor: selectedTeam === 2 ? "#0056b3" : "#007afd" }}
                >
                    NÉO PRO
                </button>
            </div>
            <div className="userteam-sort-control">
                <label>Trier par</label>
                <select onChange={handleSortChange}>
                    <option value="value">Valeur</option>
                    <option value="points">Points</option>
                </select>
            </div>
            {cyclists.length > 0 ? (
                <div className="userteam-table">
                    <div className="userteam-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>COUREUR</th>
                                    <th>ÉQUIPE</th>
                                    <th>VALEUR</th>
                                    <th>POINTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedCyclists.map((cyclist) => (
                                    <tr key={cyclist.cyclistId} onClick={() => handleRowClick(cyclist.cyclistId)}>
                                        <td>
                                            <div className="userteam-name-info">
                                                <span className="userteam-name-bonus">{cyclist.isBonus ? "x2" : ""}</span>
                                                <img 
                                                    className="select-cyclists-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality
                                                        .normalize("NFD")
                                                        .replace(/[\u0300-\u036f]/g, '')
                                                        .replace(/ /g, '_')
                                                        .toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${cyclist.nationality}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <span>{cyclist.name}</span>
                                            </div>
                                        </td>
                                        <td>{cyclist.team}</td>
                                        <td>{cyclist.finalValue}</td>
                                        <td>{cyclist.cyclistPoints}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <p>Aucun cycliste trouvé pour cette team dans cette ligue.</p>
            )}
        </section>
    );
};

export default UserTeam;